@media only screen and (max-width: 500px) {
  html,
  body {
    overflow-x: hidden !important;
    width: 100% !important;
  }
  .navbar-hamburger {
    display: block;
    margin-right: 36px;
  }
  .Navbar {
    width: 100%;
    position: fixed;
    z-index: 2;
  }
  .nav-logo {
    margin-left: 30px;
  }
  .Navbar > .navbar--links {
    position: fixed;
    flex-direction: column;
    align-items: center;
    top: 60px;
    right: -120px;
    background-color: #1b1b1b;
    justify-content: space-between;
    width: 100%;
    height: 200px;
    padding-right: 50px;
    transition: 0.3s;
    z-index: 1;
  }

  .navbar--links > li:first-child {
    margin-top: 20px;
  }
  .navbar--links > li:last-child {
    margin-bottom: 20px;
  }
  .navbar--links > li {
    z-index: -1;
    color: rgb(0, 0, 0);
  }
  .navbar--links > li:hover {
  }
  .Navbar > .navbar-links-none {
    right: -1000px;
    z-index: 1;
  }
  /* home */
  .home--section {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 60% 40%;
  }
  .home--section > div {
    margin: 0;
    padding: 0;
    align-items: center;
  }
  .intro--home > h3 {
    font-size: 0.9rem;
  }
  .intro--home > h1 {
    font-size: 4rem;
  }
  .intro--home {
    padding-bottom: 40px;
  }
  .left--section-home {
    margin-top: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  .home-section-logos {
    margin-bottom: 10px;
  }
  .right--section-home {
    display: flex;
    justify-content: flex-start;

    z-index: 0;
  }
  /* about */

  .about-grid {
    display: grid;
    grid-template-rows: 20% 30% 50%;
    grid-template-columns: none;
    padding: 0 40px;
    padding: 0;
  }
  .about-grid > * {
    padding: 0;
    margin: 0;
  }
  .about-grid > .my-profile {
  }
  .about {
    padding: 0;
    height: 70vh;
  }
  .my-profile {
    width: 140px;
    height: 140px;
  }
  .right-grid {
    font-size: 0.9rem;

    padding: 0 40px;
  }
  .left-grid {
    border: none;
    padding: 0;
    flex-direction: column;
    align-items: center;
    padding-left: 30px;
    margin: 0;
    height: 100%;
  }
  /* skills */

  .skills--section {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .left--skills {
    display: flex;
    flex-direction: column;
    order: 0;
  }
  .skills--name {
    order: 1;
  }
  .right-skills-text {
    border: none;
    z-index: 0;
  }
  .left--skills > div {
    margin: 0px;
  }
  .left--skills > div:nth-child(2) {
    margin: 10px 0;
    margin-left: 140px;
  }
  .web--skills {
    padding: 0;
    margin: 0px;
  }

  /* projects */
  .projects-section {
    padding: 0.5px 0px;
  }

  .projects--template {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 40% 60%;
    width: 200px;
    min-height: 300px;
  }

  .project--card > h1 {
    font-size: 1.5rem;
    margin-top: 100px;
  }
  .project--card > div:last-child {
    margin-bottom: 100px;
  }
  .projects--main > h1 {
    font-size: 0.9rem;
  }
  .projects--main > p {
    font-size: 0.6rem;
  }
  .main-projects > div {
    padding: 0;
  }
  .skills--name > p {
    font-size: 0.7rem;
  }
  .skills--name > h3 {
    font-size: 0.9rem;
  }
  /* contact */
  .contact-section > form {
    margin: 10px 10px;
  }
  .contact-section {
    padding: 0;
  }
  .input--textarea {
    border: none;
  }
  .input--section > textarea {
    border: none;
  }
  .contact-section > form {
    border: none;
    box-shadow: none;
  }
  .contact-label > h1 {
    font-size: 1.9rem;
    font-weight: 100;
  }
}
