/* 
    colors: 
    #2B1077 navbar dark blue


    light mode
    background #ffffff white
    font color #1b1b1b dark
    
    dark mode #d9d9d9
*/
@font-face{
    src: url(../public/fonts/Brolink-Regular.ttf);
    font-family: brolink;
}

@font-face{
    src: url(../public/fonts/Inter-Regular.ttf);
    font-family: Inter;
}
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
:root{
    --border-black:1px solid black;
    --playfair--font-family:'Playfair Display';
}


/* Home */


.home-section-main{
    background-color:#ffff;

}

.home--section{
    display: grid;
    grid-template-columns: 55% 45%;
    align-items: center;
    height: 100vh;
    width: 100%;
    font-family: var(--playfair--font-family);
}
.home-section-logos{
    filter: invert(42%) hue-rotate(87deg) brightness(50%) contrast(119%);
}
.right--section-home{
    z-index: 2;
    background-color: #ffff;
    padding-left: 140px;
    border-left: 1px solid #1b1b1b;
    height: 300px;
    display: flex;
    margin-top: 60px;
    flex-direction: column;
    justify-content: center;
    font-family: Inter;
    font-weight: 400;
    font-size: 1rem;

}
.right--section-home > h3{
    font-weight: lighter;
    font-size:1.4rem;

    color:#1b1b1b;
}
.right--section-btn{

    padding-top: 30px;

}
.right--section-btn > a{
    margin-right: 50px;
    font-weight: lighter;
    font-size: .9rem;
    color: #1b1b1b;
}
.right--section-btn a:first-child{
    margin-left: 10px;
}
.left--section-home{
    padding-right: 68px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.intro--home > h1{
    z-index: 1;

    padding: 0;
    margin: 0;
    font-size: 100px;
    color: #1b1b1b;
    font-family: var(--playfair--font-family);
}
.home-section-logos{
   
}
.intro--home > h3{
    padding: 0 0 0 -100px;
    margin: 0 0 0 -19px;
    font-weight: lighter;
    font-size: 1.5rem;
    color: #1b1b1b;
    

}
.intro--home > h3:first-child{
    margin-bottom: -17px ;
}
.next-btn > img{
    width: 40px;
}
.home-intro-facebook{
    width: 27px;
    margin-bottom: 2px;
}



.next-btn {
    position: absolute;
    right: 270px;
    border: none;
    background-color: transparent;
}

.container{
    padding: 0 200px 0 200px;
}

.Navbar{
    text-align: end;
    font-weight: bold;
    display: flex;
    top: 0;
    align-items: center;
    justify-content: flex-end;
    position: -webkit-sticky;
    position: fixed;
    width: 100%;
    z-index: 1000;
    background-color: #1b1b1b;
    padding-top: 30px;
    border-bottom: 1px solid #e2e2e2;
}

.nav-logo{
    color: #fff;
    margin-right: auto;
    font-family: brolink;
    margin-left: 100px;
    z-index: 2;
    cursor: pointer;
}

.Navbar > ul {
    display: flex;
    margin-right: 100px;
    z-index: 2;
    align-items: flex-start;
    align-self: center;
    line-height: 20px;
    font-family: Inter;
    font-weight: 400;
    font-size: 1rem;
    
}

.Navbar > ul > li {
    list-style: none;

}

.Navbar > ul > li > a{
    text-decoration: none;
    margin-left: 50px;
    color: #e2e2e2;
    cursor: pointer;
}

.Navbar > ul > li > a:hover{
    border-bottom: rgb(255, 66, 66) 1px solid;
}
.navbar-hamburger{
    cursor: pointer;
    margin-bottom: 4px;
    display: none;
}

.bar{
    background-color: #d6d6d6;
    width: 30px;
    height: 3px;
    margin: 8px auto;

}
/* About section */
.my-profile{
    width: 270px;
    height: 270px;
    border-radius: 50%;
    background: url('../public/Logos/david.JPG') no-repeat;
    background-size: cover;
    margin-right: 31px;
    box-shadow: 0 0 20px rgb(44, 44, 44), -20px 0 80px rgb(0, 0, 0);
}

.about{
    padding-top: 100px;
    padding-bottom: 70px;
    color: #e2e2e2;
    height: 55vh;
    font-family: Inter,sans-serif;
    font-weight:100;
    font-size: 1rem;
}
.about--text{
    justify-self:center;
    font-family: Inter, sans-serif;
    font-weight:400;
    font-size: 1rem;
}
.about-grid{
    display: grid;
    grid-template-columns: 10.5% 39.5% 50%;
    height: 100%;
    align-items: center;
}

.left-grid{
    height: 350px;
    display: flex;
    justify-content: center;
    padding-left: 50px;
    align-items: center;
    border-left: 1px #e2e2e2 solid;
}
.right-grid{
    padding: 0 60px 0 40px;
    font-size: 1.3em;
    font-weight: lighter;
}

.skills--about{
    width: 500px;
    display: flex;
}

.logo-skills{
    width: 45px;
    margin-right: 5px;
}

.logo-img{

    width: 36px;
    margin-left: 2px;   
    filter: brightness(0) invert(1);
}
/* Skills */
.skills--section{
    display: grid;
    grid-template-columns: 89.5% 10.5%;
    color: #e2e2e2;
    height: 60vh;
    align-items: center;
    font-family: Inter, sans-serif;
    

}
.left--skills > div{
    border:#e2e2e2 1px solid;
    width: 200px;
}

.left--skills{
    justify-self: center;
    display: flex;
    z-index: 1;
}
.tools--skills{
    margin: 0 50px;
    margin-top: 140px;
    transition-delay: 400ms;

}

.right-skills-text{
    display: flex;
    align-items: center;
    border-left:#e2e2e2 1px solid;
    height: 350px;
    display: flex;
    justify-content: center;
    z-index: 2;
    background-color: #1b1b1b;
}

.box--skills{
    display: grid;
    grid-template-columns: 50% 50%;
    
}
.skills--name > p{
    font-size: .9rem;
    line-height: 16px;
}

.skills--name > h3{
    background-color: #1b1b1b;
    position:relative;
    color: #fff;
    width: 200px;
}

.tools--skills > .skills--name > h3{
    width: 90px;

}
.web--skills{   
    min-height: 200px;
    align-self: flex-start;
    transition-delay: 600ms;
}
.tools--skills{
    min-height: 200px;

}
.game-dev-skills{
    min-height: 200px;
    align-self: flex-start;
    transition-delay: 200ms;

}
/* Projects Section */
.projects-section{
    clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
    background-color: #ffff;
    font-family: Inter, sans-serif;

}



.project--card{
    padding: 100px 0;
}

.project--card > h1{
    text-align: center;
    border-bottom: #aaaaaa .5px solid;
    padding-bottom: 10px;
}


.project--items{
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.projects--template{
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 35% 65%;
    width: 900px;
    border: 1px solid #d6d6d6;
    height: 200px;
    border-radius: 10px;
}
.projects--image > .img{
    margin-top: 15px;
}
.projects--template:last-child{
    margin-bottom: -10px;
}   
.projects--image{
    position: relative;
    height: 100%;
    width: 100%;
    
}

.button-img{
    position: absolute;
    top: 50%;
}

.button-img-right{
    right: 0;
}

.projects--image > div > img{
   width: 100%;
   height: 100%;
   padding: 0;
    border-radius: 10px;
}
.w3-content{

}
.projects--main{
    padding: 0px 30px;
    font-size: 13px;
    display: grid;
    grid-template-rows: 30% 50% 20%;
}

.projects--main > *{
    margin: 0;
}

.projects--main> h1{
    align-self: flex-end;
}

.projects--main > p{
    padding: 20px 10px 20px 10px;
}

.projects--button{
    padding-left: 10px;
}

.projects--button > a{
    text-decoration: none;
    color: #1b1b1b;
    border: 1px solid black;
    padding: 3px 8px;
    cursor: pointer;

}

.projects--button > a:hover{
    background-color:#1b1b1b;
    color: #fff;
}   


.projects--button > button{
    background-color: transparent;
    margin-right:4px;
}
.projects--seemore{
    display: flex;
    justify-content: center;
}
.projects-btn-visit{
    margin-right: 10px;
}
.ps-seemore{
    cursor: pointer;
    text-decoration: none;
    color: #1b1b1b;    
}

.projects--seemore > p:hover{
    border-bottom:rgb(255, 66, 66) 1px solid ;
}

/* Contact--section */
.contact-section{
    padding-bottom: 70px;
    padding-top: 120px;
    font-family: Inter, sans-serif;
    display: flex;

    justify-content: center;

}

.contact-section > form{
    border: #e2e2e2 1px solid;
    width:  450px;
    box-shadow: 5px 5px 0px #e2e2e2;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.contact-section > form > input{
    justify-self: center;
    align-self: center;
    padding: 10px 30px;
    border-radius: 60px;
    border: .5px solid #e2e2e2;
    margin: 10px;    
    cursor: pointer;

}
.input--section{
    display:flex;
    flex-direction: column;
}

.input--text, .input--textarea{
    margin: 4px 40px;
    border-radius: 6px;
    border: .1px solid white;
    padding:10px;
}
.input--textarea{
    padding-bottom: 60px;
}

.contact-label > h1{
    padding: 0 40px;
    color: white;

}

footer{
    display: flex;
    justify-content: center;
    padding: 10px 0;
    font-family: Inter, sans-serif;
    color: white;
}   

/* animation */
.show{
    opacity: 1;
    transition: all 1s;
    transform: translateX(0);

}

.hidden{
    opacity: 0;
    filter: blur(0);
    transition: all 1s;

    transform: translateX(20%);

}

.skill-show{
    opacity: 1;
    transition: all 1s;

    transform: translateX(0);

}

.skill-hidden{
    opacity: 0;
    filter: blur(0);
    transition: all 1s;

    transform: translateX(40%);

}

.project-show{
    opacity: 1;
    transition: all 1s;
    transform: translateX(0);

}

.project-hidden{
    opacity: 0;
    filter: blur(0);
    transition: all 1s;
    transform: translateX(10%);

}



@media(prefers-reduced-motion){
    .show{
        transition: none;
    }
}

.box--skills:nth-child(1){
    transition-delay: 300ms;
}
.box--skills:nth-child(2){
    transition-delay: 400ms;
    
}
.box--skills:nth-child(3){
    transition-delay: 600ms;
}